export enum SpaceTypeCategory {
  PlatinumPlus = 'platinum+',
  Platinum = 'platinum',
  Standard = 'standard',
  Silver = 'silver',
  Gold = 'gold'
}
export const metaCities = [
    'gurgaon',
    'bengaluru',
    'mumbai',
    'pune',
    'hyderabad',
    'noida',
    'kolkata',
    'delhi-nct',
    'coimbatore',
    'dubai',
    'indore',
    'lucknow',
    'north-goa',
    'goa',
    'surat',
    'faridabad',
    'chennai',
    'mysuru-mysore',
    'pondicherry'
]

export const banners = [
    {
      src:'assets/images/banners/day-pass.png',
      href:'https://stylework.app.link/website',
      alt:'coworking day pass offer'
    },
    {
      src:'assets/images/banners/multilocaton-membership.png',
      href:'https://stylework.app.link/website',
      alt:'flexi desk multilocation membership'
    },
    {
      src:'assets/images/banners/signup-flexboard.png',
      href:'https://corp.stylework.city/',
      alt:'flexboard dashboard signup'
    },
    {
      src:'assets/images/banners/need-help.png',
      href:'https://www.stylework.city/contact-us',
      alt:'talk to our dedicated team'
    },

    {
      src:'assets/images/banners/invite-earn.png',
      href:'https://stylework.app.link/website',
      alt:'invite and earn'
    },
    {
      src:'assets/images/banners/mysoho.png',
      href:'https://mysoho.co/?utm_source=Stylework_Website&utm_medium=Header_Banner&utm_campaign=Mysoho_FSS',
      alt:'my soho'
    }
  ]

  export const SpaceTypeImages = {
    [SpaceTypeCategory.Platinum]: 'assets/icons/platinum-badge-small.svg',
    [SpaceTypeCategory.Gold]:'assets/icons/gold-badge-small.svg',
    [SpaceTypeCategory.Silver]: 'assets/icons/silver-badge-small.svg',
    [SpaceTypeCategory.Standard]: 'assets/icons/standard-badge-small.svg',
    [SpaceTypeCategory.PlatinumPlus]: 'assets/icons/platinum-plus-badge-small.svg'
  }
export const CityWiseKeywords = `Coworking Space, Shared Office Space, Coworking Space, Cafe With Wifi, Office Space For Rent, Quiet Work Cafe, Shared Office Space, Coworking Cafe, Meeting Room`;
export const SpaceDetailsKeywords = `Coworking spaces, working space, coworking, office space, coworking space near me, meeting rooms, private cabins, desks, open desk, office day pass, rent office for a day`