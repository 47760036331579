import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CardWithImageModel } from 'src/app/_models/card-with-image';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { QueryParams } from 'src/app/shared/services/common-services/rest-api.service';

import {
  SpaceListResponse,
  SpaceService,
} from 'src/app/shared/services/spaces/space.service';
import { Amount, SeatPrice, Spaces } from 'src/app/shared/interface';
import { Helper } from 'src/app/Helper/helpers';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-popular-workspaces-gallery',
  templateUrl: './popular-workspaces-gallery.component.html',
  styleUrls: ['./popular-workspaces-gallery.component.scss'],
})
export class PopularWorkspacesGalleryComponent implements OnInit, OnDestroy {

  helper = Helper;
  @Input() sessionId = null; //used in space selection while membership purchase plan
  cardWithImageData: CardWithImageModel[] = [];
  queryObj: Partial<QueryParams> = {
    lat: 0,
    long: 0,
    count: 10,
    page: 1
  };
  latsNew: number;
  loading = false;
  customOptions: OwlOptions = {
    autoplay: false,
    autoplaySpeed: 500,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      460: {
        items: 2,
      },
      700: {
        items: 3,
      },
      760: {
        items: 4,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
    nav: true,
  };
  private subs = new SubSink();

  private destroyed$ = new Subject();

  constructor(private spaceService: SpaceService,
    private route: ActivatedRoute) {
    // do nothing
  }

  ngOnInit(): void {
    this.route.params.subscribe(prm => {

      if (Object.values(prm).length !== 0) {
        this.spaceService.spaces$
          .pipe(
            // it is now important to unsubscribe from the subject
            takeUntil(this.destroyed$)
          )
          .subscribe((spaces) => {
            if (!spaces || spaces.length === 0) {
              const queryObj = {
                lat: prm.lat || 0,
                long: prm.long || 0,
                count: prm.count || 10,
                page: prm.page || 0,
                citySlug: prm.citySlug,
                id: prm.id ? prm.id : '5ef057f2adad0bfa32d06c1c',
                name: prm.name ? prm.name : 'Hyderabad',
                popular: prm.popular ? JSON.parse(prm.popular) : false
              };
              if (prm.citySlug) {
                this.loading = true;
                this.subs.add(
                  this.spaceService
                    .getPopularSpacesByCitySlug(queryObj)
                    .subscribe((spaceResponse: SpaceListResponse) => {
                      this.addSpacesToPopularWorkSpaceGallery(spaceResponse.spaceData);
                      this.loading = false;
                    }, err => {
                      this.loading = false;
                    })
                );
              }
            }
          },
            (err) => {
              this.loading = false;
            });
      } else {
        const queryObj = {
          lat: prm.lat || 0,
          long: prm.long || 0,
          count: prm.count || 10,
          page: prm.page || 0,
          citySlug: prm.citySlug || 'gurgaon',
          id: prm.id ? prm.id : '5ef057f2adad0bfa32d06c1c',
          name: prm.name ? prm.name : 'Hyderabad',
          popular: prm.popular ? JSON.parse(prm.popular) : true,
        };
        this.loading = true;
        this.subs.add(
          this.spaceService
            .getPopularSpacesByCitySlug(queryObj)
            .subscribe((spaceResponse: SpaceListResponse) => {
              this.addSpacesToPopularWorkSpaceGallery(spaceResponse.spaceData);
              this.loading = false;
            }, err => {
              this.loading = false;
            })
        );
      }
    });

  }


  addSpacesToPopularWorkSpaceGallery(spaces: Spaces[]): void {
    this.cardWithImageData = [];
    spaces.forEach((space) => {
      let link = `/coworking-spaces/${space.cityInfo.slug}/${space.areaInfo.slug}/${space.slug}`;
      if (this.sessionId !== null) {
        link = `/membership/${this.sessionId}/space-details/${space.id}`;
      }
      if (spaces.length > 3) {
        const data = spaces.filter((res) => ((res.seatPrice?.amount && res?.seatPrice?.amount?.amount !== 0) || (res.passAmount?.amount && res?.passAmount?.amount !== 0)))
        data.forEach((result) => {
          this.cardWithImageData.push({
            image:
              result.images.length > 0 ? result.images[0] + '' : 'assets/images/img1.png',
            title: result.name || result.name,
            address: result?.address || 'NA',
            metro: result.nearby.length > 0 ? result.nearby[0].name : 'NA',
            price: this.getPassAmount(result?.passAmount),
            seatPrice: this.getSeatPriceAmount(result?.seatPrice),
            link,
            categoryBadge: this.helper.getSpaceCategoryBadge(result?.spaceCategory?.categoryName) 
            
          });
        })
      } else {
        this.cardWithImageData.push({
          image:
            space.images.length > 0 ? space.images[0] + '' : 'assets/images/img1.png',
          title: space.name || space.name,
          address: space?.address || 'NA',
          metro: space.nearby.length > 0 ? space.nearby[0].name : 'NA',
          price: this.getPassAmount(space?.passAmount),
          seatPrice: this.getSeatPriceAmount(space?.seatPrice),
          link,
          categoryBadge: this.helper.getSpaceCategoryBadge(space?.spaceCategory?.categoryName) 
        });
      }
    });

    if (this.cardWithImageData.length < 2) {
      this.customOptions.responsive = {
        0: {
          items: 1,
        }
      };
    } else {
      this.customOptions.responsive = {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        460: {
          items: 2,
        },
        700: {
          items: 3,
        },
        760: {
          items: 4,
        },
        1000: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      };
    }
  }

  getPassAmount(passAmount: Amount): string {
    if (passAmount && passAmount.amount) {
      return '₹' + Helper.getFormattedAmount(passAmount) + '/day'
    } else {
      return null;
    }
  }

  getSeatPriceAmount(seatPrice: SeatPrice): string {
    if (seatPrice && seatPrice?.amount && seatPrice?.amount?.amount) {
      return `₹${Helper.getFormattedAmount(seatPrice?.amount)}/${seatPrice.type}`
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
