<section class="work-space">
  <div class="container">
    <div class="row">
      <div class="col col-12 text-center heading">
        <h2 class="dark-0 fs-biger">Ready to get started?</h2>
        <h3 class="fs-larger fw-400 ">Change the way how you look for your workspace.<br /> Find Coworking spaces near
          you in 4 steps
        </h3>
      </div>
      <div class="col col-12 mt-5">
        <div class="position-relative mob_view">
          <div class="row justify-content-between">
            <div class="col col-4 zindex-999 rtg-sm position-relative " (mouseover)="fMembership=true"
              (mouseleave)="fMembership=false">
              <h5 class="fs-larger dark-0 position-relative"> <span class="side-border">|</span> Get our flexible
                membership
                plan</h5>
              <p class="f-14 dark-10">Especially with COVID-19 and the rise of remote-working, Stylework is providing
                you with flexible membership
                plans where you can customise the duration, choose between fixed/multi-locational options, number of
                seats and
                many more, to specifically suit your needs.</p>
              <p class="d-flex align-items-center" (click)="routeToContactUs()">
                <span class="template-color">Get Started</span>
                <span class="forward-arrow">></span>
              </p>
              <p class="grey-txt tab">
                Bookings available only through our mobile app
              </p>

            </div>

            <div class="col col-4 zindex-999 rtg-sm position-relative" (mouseover)="eWorkspaces=true"
              (mouseleave)="eWorkspaces=false">
              <h5 class="fs-larger dark-0 position-relative"> <span class="side-border">|</span>Explore workspaces</h5>
              <p class="f-14 dark-10">With more than 500 amenity-packed workspaces across India, you can choose one that
                suits your requirements. However, with Stylework, it’s more than just a workspace.</p>
              <p class="d-flex align-items-center" (click)="routeToExploreSpaces()">
                <span class="template-color">Explore Spaces</span>
                <span class="forward-arrow">></span>
              </p>
              <p class="grey-txt">
                Available through our website and mobile app.
              </p>
            </div>
          </div>

          <div class="row mt-380 justify-content-between">
            <div class="col col-4 zindex-999 rtg-sm position-relative " (mouseover)="bookSpace=true"
              (mouseleave)="bookSpace=false">
              <h5 class="fs-larger dark-0 position-relative"> <span class="side-border">|</span>Book your space</h5>
              <p class="f-14 dark-10">Discovering your ideal workspace is now more convenient than ever with the help of
                a coworking aggregator. From an array of options starting from a day pass, to
                weekly/monthly/quarterly/annual plans, booking a workspace has never been easier. Simply, browse through
                and compare multiple workspaces and choose the one that’s just right for you. As a helping hand, our
                representative will always be ready to assist you. </p>
              <p class="grey-txt">
                Bookings available through our mobile app.
              </p>
            </div>
            <div class="col col-4 zindex-999 rtg-sm position-relative" (mouseover)="bookTour=true"
              (mouseleave)="bookTour=false">
              <h5 class="fs-larger dark-0 position-relative"> <span class="side-border">|</span>Book a free tour</h5>
              <p class="f-14 dark-10">Although we are trying to provide you a virtual tour through the pictures and
                videos, you can book a free tour and our officials will walk you through the spaces and explain all the
                amenities and protocols of working there. We’re always here to help you in making your decision about
                your next workspace.</p>
              <p class="grey-txt">
                You can book through both website and our mobile app
              </p>
            </div>
          </div>
          <div class="middle-box position-relative">
            <div class="ready_list" *ngIf="!fMembership && !eWorkspaces && !bookSpace && !getPrice && !bookTour"
              [@fadeInOut]="true">
              <img class="img-fluid h-445" [src]="flexiblemembership" alt="...">
            </div>
            <div class="ready_list" [@fadeInOut]="fMembership" *ngIf="fMembership">
              <img [src]="flexiblemembership" class="d-block w-100" alt="...">
            </div>
            <div class="ready_list" [@fadeInOut]="eWorkspaces" *ngIf="eWorkspaces">
              <img [src]="explorespaces" class="d-block w-100" alt="...">
            </div>
            <div class="ready_list" [@fadeInOut]="bookSpace" *ngIf="bookSpace">
              <img [src]="bookspace" class="d-block w-100" alt="...">
            </div>
            <div class="ready_list" [@fadeInOut]="getPrice" *ngIf="getPrice">
              <img [src]="bestprice" class="d-block w-100" alt="...">
            </div>
            <div class="ready_list" [@fadeInOut]="bookTour" *ngIf="bookTour">
              <img [src]="freetour" class="d-block w-100" alt="...">
            </div>
          </div>
          <div class="row mt-4 justify-content-center position-relative">
            <div class="col col-4 rtg-sm zindex-999" (mouseover)="getPrice=true" (mouseleave)="getPrice=false">
              <h5 class="fs-larger dark-0 position-relative"> <span class="side-border">|</span>Get the best price</h5>
              <p class="f-14 dark-10">No matter what’s the size of your company, we are going to offer you the best
                price
                for your desired workspace. As we like to keep things transparent, there’s zero-brokerage involved.
                Also,
                we’ve a wide range of offers and promotions to help you get the best price for your next space.</p>
              <p class="grey-txt">
                Offers and promotions available through both website and mobile app.
              </p>
            </div>
          </div>
        </div>



      </div>
    </div>

  </div>
</section>