<div class="style-work-filters container">
    <div class="row">
      <div class="col-lg-6 col-12 p-lg-0">
        <h2 class="fs-biger line-h2"><span style="opacity: 0.8;">Explore</span> <strong> shared workspaces near you</strong></h2>
      </div>  
    </div>
    <div class="row row-flex" *ngIf="locationSelected">
      <div class="col col-4 col-md-5 p-lg-0 ">
        <div class="mb-2"><small class="fz-xs-12">Your Location</small></div> 
        <div class="d-flex justify-content-between ">
          <div class="col-lg-6 col-12 rounded text-center py-lg-4  py-3" style="background-color:#DDEFEE">     
            <img class="selected-img" src="{{selectedLocation?.newBlueIcon}}"  alt="location icon">
            <p class="text-primary-base fs-large fw-500 mt-3 m-0">{{selectedLocation?.name}}</p>
          </div>
          <div class="line-vertical d-md-none d-block d-lg-block"></div>
        </div>
      </div>
      <div class="col col-8 col-md-7 ">
        <div class="mb-2"><small class="fz-xs-12">Other Location</small></div>
        <div class="cities d-lg-flex d-md-flex flex-wrap d-none">
          <ng-container *ngFor="let city of (popularCities$  | async)">
            <div class="slide c-img mb-4 tab" (click)="routeToPopularLocations(city)">
              <div class="py-1 d-flex align-items-center justify-content-center flex-column">
                <img
                  [src]="city?.newBlueIcon" 
                  [alt]="city?.name">
              <p class="m-0 fs-small fw-500 mt-1">{{city?.name}}</p>
              </div>
             </div>
            
          </ng-container>
          <ng-container>
            <div class="c-img mb-4" (click)="openDialog()">
              <img src="assets/icons/Add-City.svg" width="94" height="96" alt="add city icon">
            </div>  
          </ng-container>
        </div>
        <div class="d-block d-lg-none d-md-none pt-4">
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide  *ngFor="let city of (popularCities$  | async)">
              <div class="slide-carousel tab" (click)="routeToPopularLocations(city)">
                <div class="py-1 d-flex align-items-center justify-content-center flex-column">
                  <img
                    [src]="city?.newBlueIcon" 
                    [alt]="city?.name">
                <p class="m-0 fs-small fw-500 mt-1">{{city?.name}}</p>
                </div>
               </div>
            </ng-template>
           <ng-template carouselSlide>
             <div class="me-lg-5 pe-lg-2 pe-1 me-3 moz_m" (click)="openDialog()">
                <img class="slide-carousel-image" src="assets/icons/Add-City.svg" alt=" add city">
             </div>
            
           </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3 explore-city container" *ngIf="!locationSelected && (popularCities$ | async).length > 0">
    <p class="heading fz-xs-10 m-0" >Where do you want to work at?</p>
      <div class="row mt-1">
        <div class="col-md-9 col-8 col-lg-10">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide  *ngFor="let city of (popularCities$  | async)">
              <div class="slide-default tab" (click)="routeToPopularLocations(city)">
                <div class="py-1 d-flex align-items-center justify-content-center flex-column">
                  <img
                    [src]="city?.newBlueIcon" 
                    [alt]="city?.name">
                <p class="m-0 fs-small fw-500 mt-1">{{city?.name}}</p>
                </div>
               </div>
            </ng-template>
          </owl-carousel-o>
        </div> 
        <div class="col-md-3 col-4 col-lg-2">
          <div class="me-lg-5 pe-lg-2 pe-1 me-4 moz_m " (click)="openDialog()">
            <img class="city-image" src="assets/icons/Add-City.svg" alt=" add city">
           </div>
        </div>
      </div> 
  </div>
  
  <div class="container mt-3 px-lg-0 " *ngIf="selectedLocation?.name">
    <h5 class="m-0 fw-400 d-none d-md-block d-lg-block">Showing <b>3</b> out of <b>{{spacesCount$ | async}}</b> workspaces in <span class="text-primary-base">{{selectedLocation?.name}}</span></h5>
    <p class="m-0 fw-400 d-block d-md-none d-lg-none">Showing <b>3</b> out of <b>{{spacesCount$ | async}}</b> workspaces in <span class="text-primary-base">{{selectedLocation?.name}}</span></p>
  </div>
  
  
 
  
  
