<section class="mt-lg-5 mt-0 p-lg-5  looking-space">
    <h2 class="text-center fs-biger space-text lh-sm mt-2 ft-20">Looking for Workspaces Instead ?</h2>
    <div class="container  d-flex justify-content-center">
        <!---Popular workspaces---->
        <div class=" popular-workspaces-container p-md-2 ps-md-3  mt-3 col-lg-10 col-12 ">
            <span class="sub-heading p-1">Popular workspaces</span>
            <div class="carosel-container mt-2">
                <app-popular-workspaces-gallery></app-popular-workspaces-gallery>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center container mt-4 pb-4">
        <button class="btn btn-primary-base py-lg-3 py-2 px-lg-5 px-4" (click)="routeToExploreSpaces()">
            <span class="btn-text">Explore Spaces</span>
        </button>
    </div>
</section>
<section class="mt-5 mb-4 container">
    <div class="d-flex justify-content-between flex-row align-items-center">
        <div class="tab" routerLink="/">
            <p class="link-text"><img src="assets/icons/upward.svg" alt="upward"> Home</p>
        </div>
        <div class="tab" routerLink="/contact-us">
            <p class="link-text"> Contact Us <img src="assets/icons/forword.svg" alt="icons"></p>
        </div>
    </div>
</section>
